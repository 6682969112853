import { fetchAndActivate, getAll } from 'firebase/remote-config';
import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';

import {
    FirebaseRemoteConfigAPI,
    MaintenanceConfig,
} from '@/services/firebase/remoteConfig';
import { Context } from '@/types/context';

const defaultMaintenanceConfig: MaintenanceConfig = {
    from: '',
    to: '',
    isInMaintenance: false,
    showToast: false,
};

const FeatureFlagsContext = createContext<Context.FeatureFlags.Value>({
    flags: {},
    fetchConfig: () => Promise.resolve(),
    maintenanceConfig: defaultMaintenanceConfig,
    xeroAuthEnabled: false,
    invoiceSystemEnabled: false,
});

const FeatureFlagsProvider = (props: Context.FeatureFlags.Props) => {
    const { defaultBetaFeatures, children } = props;
    const [flags, setFlags] = useState(defaultBetaFeatures);
    const [maintenanceConfig, setMaintenanceConfig] =
        useState<MaintenanceConfig>(defaultMaintenanceConfig);
    const [xeroAuthEnabled, setXeroAuthEnabled] = useState(false);
    const [invoiceSystemEnabled, setInvoiceSystemEnabled] = useState(false);
    const fetchConfig = useCallback(async () => {
        const { remoteConfig } = FirebaseRemoteConfigAPI;

        remoteConfig.defaultConfig = defaultBetaFeatures;

        return fetchAndActivate(remoteConfig)
            .then(() => getAll(remoteConfig))
            .then((remote) => {
                const keys = Object.keys(remote);
                const remoteFlags = keys.reduce(
                    (prev, key) => ({
                        ...prev,
                        [key]: remote[key].asBoolean(),
                    }),
                    {},
                );
                setFlags((flags) => ({ ...flags, ...remoteFlags }));
                setMaintenanceConfig(
                    FirebaseRemoteConfigAPI.getAppInMaintenanceConfig() ??
                        defaultMaintenanceConfig,
                );
                setXeroAuthEnabled(
                    FirebaseRemoteConfigAPI.getXeroAuthConfigValue(),
                );

                setInvoiceSystemEnabled(
                    FirebaseRemoteConfigAPI.getInvoiceSystemConfigValue,
                );
            })
            .catch((error) => console.error(error));
    }, [defaultBetaFeatures]);

    useEffect(() => {
        fetchConfig();
    }, [fetchConfig]);

    return (
        <FeatureFlagsContext.Provider
            value={{
                flags,
                fetchConfig,
                maintenanceConfig,
                xeroAuthEnabled,
                invoiceSystemEnabled,
            }}
        >
            {children}
        </FeatureFlagsContext.Provider>
    );
};

function useFeatureFlagsContext() {
    const featureFlagsContext = useContext(FeatureFlagsContext);

    return featureFlagsContext;
}

export { FeatureFlagsContext, FeatureFlagsProvider, useFeatureFlagsContext };
